/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav__wrapper {
  margin-left: $space * -2.5;

  @include bp-down(medium) {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    max-height: 100vh;
    margin-left: 0;
    padding-top: $space * 5;
    padding-bottom: 210px;
    background: $color-mint url('#{$img_dir}/header-bottles.png') no-repeat center bottom;
    background-size: 255px auto;
    text-align: center;
    transform: translateY(-100%);
    transition: $global-transition;
  }

  .nav-active & {
    @include bp-down(medium) {
      transform: translateY(0);
    }
  }
}

.c-main-nav {
  display: inline-block;
  margin: 0;
  margin-top: $space * 3;
  text-align: left;
  overflow: auto;

  @include bp(medium) {
    display: block;
    overflow: hidden;
  }
}

.c-main-nav__item {
  list-style: none;
  margin: 0;
  padding: 0;
}

.c-main-nav__link {
  display: block;
  padding: $space;
  text-decoration: none;
  font-family: $font-b;
  font-size: 30px;
  color: $color-black;
  text-transform: uppercase;

  @media (max-height: 800px) and (min-width: 768px) {
    font-size: 26px;
  }

  &:hover {
    color: $color-white;
  }
}

.c-btn--close {
  position: absolute;
  right: 0;
  top: $space;

  @include bp(medium) {
    display: none;
  }
}
