/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix();
  max-width: 1200px;
  margin-right: auto;
  margin-left: auto;
  padding-left: $space * 1.5;
  padding-right: $space * 1.5;
}

.o-wrapper--narrow {
  max-width: 900px;
}
