.c-columns {
  flex: 1;

  @include bp(xlarge) {
    display: flex;
  }
}

.c-column__item {
  display: flex;
  flex-direction: column;
}

.c-column__item--left {
  @include bp(xlarge) {
    width: 58%;
    align-items: stretch;
  }

  &.full {
    width: 100%;
  }

  .c-tease--post {
    height: 100%;
  }
}

.c-column__item--right {
  @include bp(xlarge) {
    width: 42%;
  }
}

.c-columns--wrap {
  flex-wrap: wrap;
}

.c-columns--col-3 {
  width: 100%;
  flex: 0 0 100%;
  margin: 0 auto;
  padding: 3px;

  @include bp(550px) {
    width: 50%;
    flex: 0 0 50%;
  }
  
  @include bp(large) {
    width: 33.333333%;
    flex: 0 0 33.333333%;
  }

  .c-tease {
    height: 100%;
  }
}
