/* ==========================================================================
   #BUTTONS
   ========================================================================== */

/**
 * This is an example component. Extend inuitcss by building your own components
 * that make up your UI. Component classes are prefixed with a `c-`.
 */

/**
 * 1. Allow us to style box model properties.
 * 2. Line different sized buttons up a little nicer.
 * 3. Make buttons inherit font styles (often necessary when styling `input`s as
 *    buttons).
 * 4. Reset/normalize some styles.
 * 5. Force all button-styled elements to appear clickable.
 */

button {
  border: none;
  outline: none;
  border-radius: 0;
}

.c-btn {
  display: inline-block;
  /* [1] */
  vertical-align: middle;
  /* [2] */
  font: inherit;
  /* [3] */
  font-family: $font-m;
  font-weight: bold;
  text-align: center;
  /* [4] */
  text-transform: uppercase;
  text-decoration: none;
  margin: 0;
  /* [4] */
  cursor: pointer;
  /* [5] */
  padding: $space $space * 2;
  border-radius: 0;
  color: $color-black;
  background-color: $color-yellow;
  transition: $global-transition;

  @include bp(medium) {
    font-size: 20px;
  }

  @include bp(large) {
    font-size: 26px;
  }

  &:hover {
    color: $color-yellow;
    background-color: $color-black;
  }
}

/* Style variants
   ========================================================================== */

.c-btn--primary {
  background-color: $color-link;

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
    /* [4] */
    color: $color-white;
  }

  &:hover,
  &:focus {
    background-color: $color-hover;
  }
}

/* Size variants
   ========================================================================== */

.c-btn--full {
  display: block;
}

.c-btn--search {
  width: 100%;
  margin-top: $space;
  margin-right: $space;
  padding-left: $space * 5;
  padding-right: $space * 5;
  box-shadow: 5px 5px 6px 0 rgba($color-black, 0.25);

  @include bp(large) {
    font-size: 20px;
  }

  @include bp(xlarge) {
    width: auto;
    margin-top: 0;
    margin-left: $space * 2.5;
  }
}

.c-btn--clear {
  &,
  &:hover {
    background-color: transparent;
  }
}

.c-btn--nav {
  width: 50px;
  padding: $space * 0.5;

  @include bp(medium) {
    display: none;
  }

  span {
    display: block;
    background-color: $color-white;
    width: 100%;
    height: 5px;
    margin: 4px 0;
  }
}

.c-btn--back {
  display: inline-block;
  margin-bottom: $space;
  text-transform: uppercase;
  text-decoration: none;
  font-size: 13px;
  color: $color-black;
  background-color: none;
  background: none;
  border: none;
  outline: none;
}
