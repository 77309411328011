.c-search__form {
  display: flex;
  width: 100%;

  @include bp-down(medium) {
    position: absolute;
    top: 100%;
    right: 0;
    width: 100%;
    transform: translateX(100%);
    transition: $global-transition;
  }

  .search-active & {
    @include bp-down(medium) {
      transform: translateX(0);
    }
  }

  @include bp(medium) {
    width: 240px;
    margin-top: $space * 2;
  }

  @include bp(large) {
    margin-top: $space * 4;
    margin-bottom: $space;
  }
}

.c-search__form-submit {
  padding: 8px 14px;
  background-color: $color-yellow;
  cursor: pointer;

  &:hover {
    background-color: lighten($color-yellow, 15%);
  }
}

.c-search__form-label {
  @include bp-down(medium) {
    width: 100%;
  }
}