.c-archive {
  width: 100%;

  .c-tease__top .h4 {
    display: none;
  }

  .c-column__item--right {
    display: flex;
    flex-direction: column;
    padding-top: $space * 4.5;
    padding-bottom: $space * 4.5;
    background: url('#{$img_dir}/bubble-bg.jpg') no-repeat center center;
    background-size: cover;

    @include bp(large) {
      padding: 0 $space * 3;
    }
  }

  .c-column__item--left {
    .c-btn--full {
      display: none;
    }
  }
}

.c-archive__header {
  position: relative;
  box-shadow: 0 8px 10px 0 rgba($color-black, 0.25);

  .c-hero {
    @include bp-down(medium) {
      padding-right: $space * 1.5;
    }
  }

  .c-hero__title {
    color: $color-black;

    @include bp-down(medium) {
      font-size: 50px;
      line-height: 1.1;
    }
  }

  .c-hero__subtitle {
    max-width: 500px;

    @include bp-down(medium) {
      margin-top: $space * 2;
      font-size: 18px;
    }

    @include bp(medium) {
      font-size: 30px;
    }
  }
}

.c-archive__empty {
  padding: $space * 3 $space * 6;
}

.c-archive__heading {
  font-family: $font-m;
  font-weight: normal;
  text-transform: none;

  @include bp(large) {
    font-size: 30px;
  }
}

.c-archive__heading--left {
  padding: $space * 2 $space * 1.5 0;
  background-color: $color-white;

  @include bp(medium) {
    padding: $space * 3 $space * 2 0;
  }

  @include bp(large) {
    padding: $space * 3 $space * 6 0;
  }
}

.c-archive__heading--right {
  padding: $space * 3 $space * 2;
  background-color: $color-very-light-grey;
}

.c-archive__col-right {
  flex: 1;
  background-color: $color-very-light-grey;
  overflow: auto;

  @include bp-down(large) {
    min-height: 300px;
  }
}

.c-archive__scrolling {
  text-transform: uppercase;
  text-align: center;
  font-size: 20px;
  padding: $space * 2 $space * 2;
  background-color: $color-very-light-grey;
}

.hide-content.c-archive__col-right {
  .c-tease {
    display: none;
  }
}

.c-archive--filtered {
  background-color: $color-very-light-grey;
}

.c-archive--filtered-content {
  padding: $space * 1.5;

  @include bp(550px) {
    display: flex;
    padding: $space * 2.7;
  }
}

.c-archive--filtered-more {
  padding: $space * 2 0 $space * 4;
}
