[class^='icon-'] {
  display: inline-block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  vertical-align: middle;
}

.icon-search {
  width: 32px;
  height: 32px;
  background-image: url('#{$img_dir}/icon-search.png');
}

.icon-close {
  width: 32px;
  height: 32px;
  background-image: url('#{$img_dir}/icon-close.png');
}
