.c-newsletter {
  align-items: stretch;
  padding: $space * 2.5;
  background-color: $color-mint;

  @include bp(medium) {
    display: flex;
    background: $color-mint url('#{$img_dir}/newsletter-bg.png') no-repeat right top $space;
    background-size: auto 110px;
  }

  .gform_wrapper {
    position: relative;
    margin: 0;
    padding-top: $space * 0.5;

    @include bp(medium) {
      padding-right: $space * 5.5;
    }
  }

  .gform_wrapper form {
    display: flex;
    align-items: flex-end;
  }

  .gform_validation_error.gform_wrapper form {
    flex-wrap: wrap;
  }

  .gform_wrapper .gform_footer {
    position: relative;
    width: auto;
    margin: 0;
  }

  .gform_wrapper .gform_body {
    width: 100%;
    width: calc(100% - 60px);
  }

  .gform_wrapper .gform_footer,
  .gform_wrapper ul.gform_fields li.gfield {
    margin: 0;
    padding: 0;
  }

  .gform_wrapper .top_label .gfield_label,
  .gform_wrapper legend.gfield_label {
    display: none;
  }

  .gform_wrapper .top_label div.ginput_container {
    margin: 0;
  }

  .gform_wrapper .gform_footer input[type=submit] {
    width: 60px;
    height: 68px;
    margin: 0 !important;
    background: $color-purple url('#{$img_dir}/icon-arrow.png') no-repeat center center;
    background-size: 26px 22px;
    font-size: 0;
    color: $color-purple;
    cursor: pointer;
    transition: $global-transition;

    @include bp(640px) {
      height: 52px;
    }

    &:hover {
      background-color: lighten($color-purple, 20%);
    }
  }

  .gform_wrapper input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]) {
    padding: $space * 1.6;
    font-family: $font-m;
  }

  .gform_wrapper.gform_validation_error .gform_body ul li.gfield.gfield_error:not(.gf_left_half):not(.gf_right_half) {
    max-width: 100% !important;
    margin: 0 !important;
    border: none;
    background: none;
  }

  .gform_wrapper li.gfield_error input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
  .gform_wrapper li.gfield_error textarea,
  .gform_wrapper.gf_browser_chrome .gfield_error input[type=email],
  .gform_wrapper.gf_browser_chrome .gfield_error input[type=number],
  .gform_wrapper.gf_browser_chrome .gfield_error input[type=password],
  .gform_wrapper.gf_browser_chrome .gfield_error input[type=tel],
  .gform_wrapper.gf_browser_chrome .gfield_error input[type=text],
  .gform_wrapper.gf_browser_chrome .gfield_error input[type=url],
  .gform_wrapper.gf_browser_chrome .gfield_error select,
  .gform_wrapper.gf_browser_chrome .gfield_error textarea,
  .gform_wrapper.gf_browser_chrome li.gfield_error.field_sublabel_above .ginput_complex input[type=text],
  .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.ginput_container,
  .gform_wrapper li.gfield.gfield_error.gfield_contains_required label.gfield_label {
    margin: 0;
    border: none;
  }

  .gform_wrapper ul.gform_fields li.gfield {
    position: relative;
  }

  .gform_ajax_spinner,
  .gform_wrapper li.gfield.gfield_error.gfield_contains_required div.gfield_description {
    position: absolute;
    top: 100%;
    padding: 0;
  }

  .gform_ajax_spinner {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
  }

  .gform_wrapper .top_label .gfield_error {
    width: 100%;
  }

  .gform_wrapper div.validation_error {
    margin-bottom: $space;
    padding: $space 45px $space 0;
    font-size: 14px;
    text-align: left;
  }
}

.c-newsletter__title {
  max-width: 400px;
  margin-bottom: $space * 1.5;

  @include bp(medium) {
    font-size: 30px;
  }
}

.c-newsletter__socials {
  display: flex;
  justify-content: space-between;
  max-width: 280px;
  margin: 0 auto $space * 1.5;

  @include bp(medium) {
    flex-direction: column;
    max-width: 100%;
    margin: 0;
    padding-right: $space * 2.5;
  }
}

.c-newsletter__social {
  display: block;
  width: 30px;
  height: 30px;
  text-align: center;

  @include bp(medium) {
    margin-bottom: 30px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  img {
    width: auto;
    height: 100%;
  }
}

.gform_confirmation_wrapper {
  text-align: center;

  @include bp(medium) {
    padding-right: 100px;
    text-align: left;
  }
}
