.c-about {
  flex: 1;
  position: relative;
  padding: $space * 2.5 $space * 1.5;
  background-color: $color-purple;
  color: $color-white;
  font-size: 16px;

  @include bp(medium) {
    padding: $space * 2.5 $space * 14 $space * 1.5 $space * 6;
    background: $color-purple url('#{$img_dir}/about-bg.jpg') no-repeat right center;
    background-size: cover;
  }

  &:after {
    @include bp(medium) {
      content: '';
      position: absolute;
      right: $space * 2;
      bottom: 0;
      display: block;
      width: 108px;
      height: 284px;
      max-height: 95%;
      background: url('#{$img_dir}/mark.png') no-repeat center bottom;
      background-size: contain;
    }
  }
}