.scrollbar::-webkit-scrollbar {
  width: $space * 1.5;
}

.scrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #e0dede;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: #8c8c8c;
}
