.c-hero {
  padding: $space * 1.5;
  padding-right: $space * 8;
  background-color: $color-white;

  @include bp(medium) {
    padding: $space * 6;
    padding-right: $space * 10;
  }

  @include bp(xlarge) {
    padding-right: $space * 14;
  }

  &.has-filters {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }
}

.c-hero__title {
  text-transform: uppercase;
  color: $color-white;
}

.c-hero__subtitle {
  @extend h3;
  font-family: $font-m;
  text-transform: none;
}

.c-hero .c-hero__filters-toggle {
  @include bp-down(medium) {
    display: none;
  }
}

.c-hero__filters-toggle {
  display: block;
  margin-top: $space * 2;
  padding-top: $space * 7;
  background-image: url('#{$img_dir}/icon-filter-active.png');
  background: transparent url('#{$img_dir}/icon-filter.png') no-repeat center top;
  background-size: 65px auto;
  font-family: $font-m;
  font-size: 30px;
  cursor: pointer;

  &.active {
    background-image: url('#{$img_dir}/icon-filter-active.png');
  }

  &.hide {
    margin-right: 6px;
  }
}

.c-hero__filters.has-filters {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  min-height: 100%;
  display: none;
  padding-bottom: $space * 2;
  background-color: $color-white;
  box-shadow: 0 8px 10px 0 rgba($color-black, 0.25);

  &.active {
    display: flex;
  }
}

.c-hero__filters-wrapper {
  width: 100%;
  max-width: 580px;
}

.c-hero__filters-title {
  margin-bottom: $space;
  font-size: 20px;
  text-transform: uppercase;
}

.c-hero__filters-cols {
  display: flex;
  flex-wrap: wrap;

  &.tags {
    margin-left: $space * -0.5;
    margin-right: $space * -0.5;
  }
}

.c-hero__filters-bottom {
  padding-top: $space * 2;

  > div {
    @include bp-down(medium) {
      flex: auto;
    }
  }
}

.c-hero__filters-bottom-title {
  margin-bottom: $space;
  padding-right: $space * 1.5;
  text-transform: uppercase;

  @include bp(xlarge) {
    margin-bottom: 0;
  }
}

.c-hero__filters-bottom-select {
  width: 100%;
  margin-bottom: $space;
  padding: $space * 1.25;
  background-color: $color-very-light-grey;
  border: none;
  border-radius: 0;
  outline: none;

  @include bp(xlarge) {
    margin-bottom: 0;
  }
}

.c-hero__filters-bottom-btn {
  width: 100%;

  @include bp(xlarge) {
    width: auto;
  }
}
