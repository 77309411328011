input {
  width: 100%;
  padding: $space * 1.5;
  background-color: white;
  border: none;
  border-radius: 0;
  outline: none;
  font-size: 16px;
  font-family: $font-m;
}
