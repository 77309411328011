.c-tease {
  padding: $space * 1.5;
  background-color: $color-white;

  @include bp(medium) {
    padding: $space * 2.5;
  }

  @include bp(large) {
    padding: $space * 3 $space * 6;
  }

  .read-more {
    display: block;
    margin-top: $space;
    font-size: 30px;
    color: $color-red;
    text-decoration: none;

    &:after {
      content: '';
      display: inline-block;
      width: 35px;
      height: 25px;
      margin-left: $space * 2;
      background: url('#{$img_dir}/icon-arrow-red.png') no-repeat center center;
      background-size: contain;
      vertical-align: middle;
    }

    span {
      display: inline-block;
      vertical-align: middle;
    }
  }
}

.c-tease__date {
  margin: 0;
  font-family: $font-m;
  font-size: 14px;
  text-transform: uppercase;
}

.c-tease__title {
  font-size: 24px;

  @include bp(medium) {
    font-size: 30px;
  }

  &,
  a {
    color: $color-black;
    text-decoration: none;
  }
}

.c-tease__thumb {
  margin-top: $space;
  margin-bottom: $space * 2;
  margin-left: $space * -1.5;
  margin-right: $space * -1.5;
  padding: 0 $space * 1.5;
  text-align: center;
  background-color: $color-fill;

  @include bp(medium) {
    margin-left: $space * -2.5;
    margin-right: $space * -2.5;
    padding: 0 $space * 2.5;
  }

  @include bp(large) {
    margin-left: $space * -6;
    margin-right: $space * -6;
    padding: 0 $space * 6;
  }
}

.c-tease__thumb--small {
  margin: 0;
  padding: 0;
}

.c-tease__thumb-link {
  display: inline-block;
}

.c-tease__content {
  margin-bottom: $space;
  overflow: auto;

  @include bp(medium) {
    margin-bottom: $space * 3;
  }

  .home & {
    min-height: 200px;
  }
}

.c-tease--cat-paring-down-pairings {
  .c-btn {
    margin-top: $space * 1.5;
  }
}

.c-tease--small {
  margin-bottom: $space;
  padding: 0;

  &:last-child {
    margin-bottom: 0;
  }

  .c-tease__date {
    margin-bottom: $space;
  }
}

.c-tease--small__content {
  display: flex;
  align-items: flex-start;
  text-decoration: none;

  &:hover {
    color: $color-black;
  }
}

.c-tease__small-texts {
  flex: 1;
  padding: $space $space * 1.5;

  @include bp(large) {
    padding: $space $space * 3;
  }
}

.c-tease--filtered {
  display: block;
  height: 100%;
  text-decoration: none;

  .c-tease__small-texts {
    padding-top: $space * 1.5;
    padding-bottom: $space * 1.5;
  }

  img {
    width: 100%;
  }
}
