.c-main__wrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
}

.c-main {
  flex: 1;
  display: flex;
  width: 100%;
  align-items: stretch;
}

.c-main__article {
  display: flex;
  flex-direction: column;
}
