body {
  min-height: 100vh;
  padding-top: 72px;
  padding-left: 0;
  
  @include bp(medium) {
    padding-top: 0;
    padding-left: 320px;
  }
}

