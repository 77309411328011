/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: $space * 1.5;
  background-color: $color-mint;
  z-index: 1;

  @include bp(medium) {
    flex-direction: column;
    width: 320px;
    height: 100%;
    background-color: $color-mint;
    padding-bottom: 0;
    overflow: auto;
  }

  @include bp(large) {
    padding: $space * 4 $space * 2 0 $space * 2;
  }

  @media (max-height: 800px) and (min-width: 768px) {
    padding: $space * 2;
    padding-bottom: 0;
  }
}

.c-header__logo {
  display: block;

  @include bp(medium) {
    width: 240px;
    padding: $space * 2;
  }
}

.c-header__logo-text {
  @include hidden-visually;
}

.c-header__logo-img {
  display: none;

  @include bp(medium) {
    display: block;
  }

  @media (max-height: 800px) {
    width: 60%;
    margin: 0 auto;
  }
}

.c-header__logo-img--mobile {
  position: absolute;
  left: 50%;
  top: 50%;
  display: block;
  width: 80px;
  margin-left: -40px;
  transform: translateY(-50%);

  @include bp(medium) {
    display: none;
  }
}

.c-hero__filters-toggle.c-header__filters-toggle {
  display: block;
  width: 35px;
  height: 30px;
  margin: 0;
  margin-left: auto;
  padding: 0;
  background-size: contain;

  @include bp(medium) {
    display: none;
  }
}

.c-header__search-toggle {
  @include bp(medium) {
    display: none;
  }
}

.c-header__img {
  display: none;

  @include bp(medium) {
    display: block;
    width: calc(100% + 20px);
    max-width: 200%;
    margin-top: auto;
  }

  @include bp(large) {
    width: calc(100% + 40px);
  }
}
