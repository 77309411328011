/* ==========================================================================
   #FONT-FACE
   ========================================================================== */

/**
 * Add font-display: swap to font-face blocks here
 * https://www.zachleat.com/web/comprehensive-webfonts/#font-display
 */

@font-face {
  font-family: 'Cooper';
  src: url('#{$assets_dir}/fonts/cooperhewitt-book-webfont.woff2') format('woff2'),
       url('#{$assets_dir}/fonts/cooperhewitt-book-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
