/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  padding: $space;
  font-family: $font-m;
  font-size: 12px;
  color: $color-white;
  background-color: $color-black;
  text-align: center;

  @include bp(medium) {
    font-size: 14px;
  }
}
