.home {
  .c-hero {
    background: $color-red url('#{$img_dir}/header-bg-mobile.png') no-repeat right center;
    background-size: auto 100%;

    @include bp-down(medium) {
      padding-top: $space * 4;
      padding-bottom: $space * 4;
      padding-left: $space * 1.5;
    }

    @include bp(xlarge) {
      background-image: url('#{$img_dir}/header-bg.png');
    }

    @include bp-between(xlarge, 1350px) {
      background-position: right -60% center;
    }

    @include bp-between(1350px, 1500px) {
      background-position: right -30% center;
    }
  }

  .c-column__item--left {
    padding: $space * 4.5 0;
    background: url('#{$img_dir}/bubble-bg.jpg') no-repeat center center;
    background-size: cover;

    @include bp(xlarge) {
      padding: 0 $space * 3;
    }

    @include bp(full) {
      padding: 0 $space * 5.5;
    }
  }

  .c-column__item--right .c-tease__content {
    margin-bottom: 0;
    max-height: 400px;

    @include bp-down(xlarge) {
      height: 0;
    }
  }

  .c-tease--cat-paring-down-pairings .c-btn {
    @include bp-down(xlarge) {
      display: block;
      margin-top: $space;
    }
  }
}
