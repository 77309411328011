.c-checkbox__wrapper {
  position: relative;
  margin-bottom: $space * 1.5;
  padding-right: $space * 0.5;
  padding-left: $space * 0.5;
  cursor: pointer;
  
  &.drinks {
    padding-right: $space;
    padding-left: 0;
    
    @include bp(medium) {
      width: 50%;
    }
  }
}

.c-checkbox {
  @include hidden-visually;

  &:checked {
    +.c-checkbox__box,
    +.c-checkbox__name-box {
      background-color: $color-mint;
      border-color: $color-mint;
      color: $color-white;
    }
  }
}

.c-checkbox__box {
  display: inline-block;
  width: $space * 2.2;
  height: $space * 2.2;
  margin-right: $space * 0.5;
  border: 2px solid $color-light-grey;
  vertical-align: middle;
  transition: $global-transition;
}

.c-checkbox__name {
  display: inline-block;
  vertical-align: middle;
}

.c-checkbox__name-box {
  display: inline-block;
  padding: $space * 0.5 $space * 1.5;
  border: 2px solid $color-light-grey;
  transition: $global-transition;
}