/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1 {
  font-size: 35px;

  @include bp(large) {
    font-size: 50px;
  }

  @include bp(xlarge) {
    font-size: 80px;
  }
}

h2 {
  font-size: 30px;

  @include bp(large) {
    font-size: 45px;
  }
}

h3 {
  font-size: 25px;

  @include bp(large) {
    font-size: 35px;
  }
}

h4,
.h4 {
  font-size: 20px;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

h1,
h2,
h3,
h4,
.h4,
h5,
h6 {
  margin: 0;
  font-family: $font-b;
  font-weight: 400;
  text-transform: uppercase;
}

.h4 {
margin-bottom: $space * 0.5;
  font-family: $font-m;
}
